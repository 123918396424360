import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  Heading,
  Input,
  Text,
} from "@theme-ui/components"
import DatoCmsSearch from "datocms-search/dist/datocms-search.base"
import { Link } from "gatsby"
import { LanguageContext } from "../locales/langContext"

const client = new DatoCmsSearch("6b1d49c5d46c203218757274b12ce1", "production")

const Search = ({ locale }) => {
  const [query, setQuery] = useState("")
  const [currentQuery, setCurrentQuery] = useState("")
  const [results, setResults] = useState([])
  const handleSubmit = event => {
    event.preventDefault()
    setCurrentQuery(query)
    // console.log(currentQuery)
  }

  useEffect(() => {
    // Update the document title using the browser API
    client
      .search(currentQuery, { locale: locale })
      .then(function (response) {
        console.info(response.results)
        // [
        //   {
        //     title: "The Crucifix by Brunelleschi",
        //     body: "...Santa Maria Novella in <strong class=\"highlight\">Florence</strong>..."
        //     url: "http://www.smn.it/en/opere/5104-the-crucifix-by-brunelleschi/",
        //   },
        //   ...
        // ]
        console.info(response.total)
        setResults(response.results)
        // 42
      })
      .catch(function (error) {
        console.error(error)
      })
  }, [currentQuery])

  return (
    <LanguageContext.Consumer>
      {t => (
        <Container>
          <Box as="form" name="search" sx={{ mb: 6 }}>
            <Input
              onChange={e => setQuery(e.target.value)}
              type="text"
              name="query"
              id="query"
              placeholder={`${t.search}...`}
              value={query}
              required
              sx={{ mb: 3, padding: 3, fontSize: 3 }}
            />
            <Button
              onClick={handleSubmit}
              sx={{
                paddingY: 3,
                paddingX: 4,
                fontWeight: "bold",
                fontSize: 3,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "dark",
                },
              }}
            >
              {t.search}
            </Button>
          </Box>
          <Box>
            {results.length > 0 ? (
              <Box>
                <Heading sx={{ fontSize: 3, mb: 3 }}>
                  {currentQuery}: {results.length} {t.results}
                </Heading>
                {results.map((result, index) => (
                  <Box sx={{ mb: 4 }} key={index}>
                    <Link to={result.url}>
                      <Heading
                        sx={{ color: "primary", fontSize: 5, mb: 3 }}
                        dangerouslySetInnerHTML={{
                          __html: result.title.split("|")[0],
                        }}
                      />
                    </Link>
                    <Box dangerouslySetInnerHTML={{ __html: result.body }} />
                  </Box>
                ))}
              </Box>
            ) : (
              <Text>{t.noResults}</Text>
            )}
          </Box>
        </Container>
      )}
    </LanguageContext.Consumer>
  )
}

export default Search
